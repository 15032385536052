<template>
  <v-dialog v-model="dialog" persistent width="450">
    <v-card>
      <div style="width: 100%; height: 400px; max-height: 100%; border-radius: 8px">
        <v-layout class="fill-height pa-2" column style="border-radius: inherit">
          <div style="flex: none; height: 80px; width: 100%; background: var(--v-primary-base); border-radius: inherit">
            <v-layout align-center class="fill-height pa-3" style="font-size: 26px; font-weight: bold">
              Upload
              <v-spacer />
              <v-btn icon @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-layout>
          </div>
          <div style="flex: none; height: fit-content; width: 100%;">
            <v-layout align-center class="px-2">
              <v-radio-group v-model="uploadType" dense hide-details row>
                <v-radio label="Raster" value="raster"></v-radio>
                <v-radio label="Vector" value="vector"></v-radio>
              </v-radio-group>
            </v-layout>
          </div>
          <v-layout
            align-center
            class="pa-3 my-2"
            column
            justify-center
            style="flex: 1; border-radius: inherit; background: var(--v-bgListItem-base)"
          >
            <v-layout align-center justify-center style="flex: 1; width: 100%; border-radius: inherit">
              <DragBox :accept-types.sync="acceptFormat" :files.sync="files" />
            </v-layout>
          </v-layout>
          <div style="flex: none; height: fit-content; width: 100%;">
            <v-layout align-center class="fill-height">
              <v-btn :disabled="!files.length" color="primary" rounded width="100%" @click="submitUpload"
                >Submit
              </v-btn>
            </v-layout>
          </div>
        </v-layout>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import DragBox from '@/components/DragBox.vue'

export default {
  components: { DragBox },
  data() {
    return {
      dialog: false,
      uploadType: 'raster',
      files: [],
      acceptFormat: '.tif,.tiff',
      valid: false,
      rules: {
        fileRequired: value => !!value || 'File is required',
      },
    }
  },
  watch: {
    uploadType(val) {
      switch (val) {
        case 'raster':
          this.acceptFormat = '.tif,.tiff'
          break
        case 'vector':
          this.acceptFormat = '.geojson'
          break
      }
    },
    dialog() {
      this.files = []
    },
  },
  methods: {
    submitUpload() {
      console.log(this.files)
    },
  },
}
</script>

<style scoped></style>
