<template>
  <v-layout column style="width: 100%; height: 100%; overflow-y: hidden">
    <v-container style="max-width: 1640px; height: 100%; overflow: hidden; position: relative">
      <v-layout align-center class="fill-height">
        <div style="height: 85vh; width: 100%; background: var(--v-bgContainer-base); border-radius: 13px;">
          <v-layout class="fill-height overflow-y-hidden px-5" column>
            <div
              style="flex: none; height: 88px; width: 100%; text-align: center; border-bottom: 1px solid rgba(93, 101, 136, 0.5)"
            >
              <v-layout align-center class="fill-height px-5">
                <v-btn class="mr-2" dark fab small @click="$router.push('/admin')">
                  <v-icon>icon-arrow_left</v-icon>
                </v-btn>
                <h3 class="text-uppercase">CMS</h3>
                <v-spacer />
              </v-layout>
            </div>
            <v-row no-gutters>
              <v-col v-for="item in menus" :key="item.name" class="pa-4" cols="12" lg="3" md="4" sm="6" xl="3" xs="12">
                <div class="admin-menu-card d-flex flex-column justify-center align-center" @click="goToLink(item)">
                  <v-icon size="100">{{ item.icon }}</v-icon>
                  <div class="mt-2">{{ item.name }}</div>
                </div>
              </v-col>
            </v-row>
          </v-layout>
        </div>
      </v-layout>
    </v-container>
    <Upload ref="upload" />
  </v-layout>
</template>

<script>
import Upload from '@/views/admin-side/upload/Upload.vue'

export default {
  name: 'Project',
  components: { Upload },
  data() {
    return {
      menus: [
        { name: 'Domains', icon: 'mdi-domain', link: '/admin/cms/domains' },
        { name: 'Groups', icon: 'mdi-group', link: '/admin/cms/groups' },
        { name: 'Menus', icon: 'mdi-menu', link: '/admin/cms/menus' },
        { name: 'Menu Structure', icon: 'mdi-file-tree', link: '/admin/cms/structure' },
      ],
    }
  },

  mounted() {},
  methods: {
    goToLink(item) {
      if (item.newTab) {
        window.open(item.link, '_blank')
      } else {
        this.$router.push(item.link)
      }
    },
  },
}
</script>

<style scoped>
.admin-menu-card {
  height: 200px;
  background: #2f324180;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
  width: 100%;
}

.admin-menu-card:hover {
  box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.16);
  transform: scale3d(1.05, 1.05, 1.05);
  color: white;
  font-weight: bold;
}
</style>
